<template>
    <div>
        <visualiza-dados 
            :cabecalhos_colunas="cabecalhos_colunas"
            :id_registro="id_registro" 
            :titulo_listagem="titulo_listagem"
            :apiConsultarRegistros="apiConsultarRegistros"
            :apiExcluirRegistro="apiExcluirRegistro"
            :coluna_ordenar="coluna_ordenar"
            @cadastrarItem="cadastrarItem"
            @editarItem="editarItem"
            :mensagem_exclusao="mensagem_exclusao"
            :mostra_botao_cadastrar="mostra_botao_cadastrar"
        />
    </div>
</template>

<script>
import VisualizaDados from '@/components/crud/VisualizaDados.vue';
import {api} from '@/network/Api';

export default {
    components: {
        VisualizaDados
    },
    data() {
        return {
            titulo_listagem: 'Comunidade',
            cabecalhos_colunas: [
                {
                    text: 'Nome',
                    value: 'nome',
                    align: 'start',
                    sortable: true,
                    filterable: true
                },
                {
                    text: 'Endereço',
                    value: 'endereco',
                    align: 'start',
                    sortable: true,
                    filterable: true
                },
                {
                    text: 'Telefone',
                    value: 'telefone',
                    align: 'start',
                    sortable: true,
                    filterable: true
                },
                { 
                    text: 'E-mail', 
                    value: 'email',
                    align: 'start',
                    sortable: false,
                    filterable: false
                },
                { 
                    text: 'Ações', 
                    value: 'actions', 
                    align: 'end',
                    sortable: false 
                }
            ],
            coluna_ordenar: 'nome',
            apiConsultarRegistros: api.comunidadeListarTodas,
            apiExcluirRegistro: api.comunidadeExcluir,
            mensagem_exclusao: 'Tem certeza que deseja excluir esta comunidade?',
            id_registro: 'idcomunidade',
            mostra_botao_cadastrar: true,
        }
    },
    methods: {
        initialize() {
            setTimeout(() => {
                // Caso já exista um registro o botão cadastrar é desativado pois só deve existir uma comunidade.
                if ( this.$refs.childComponentRef.total_registros > 0 ) {
                    this.mostra_botao_cadastrar = false;
                }
            }, 100);
          },
        cadastrarItem() {
          this.$router.push({name: 'ComunidadeInsert'});
        },
        editarItem(params) {
          this.$router.push({name: 'ComunidadeEdit', params: {id: params.id}});
        },
      },
    };
  </script>
  
  <style scoped></style>
  