export default {
  data() {
    return {
      mixinData: 'This is mixin data',
    };
  },
  methods: {
    getColor(status) {
      return status ? 'green' : 'red';
    },
    getColorValor(valor) {
      return valor == '0.00' ? 'green' : 'red';
    },
    formataTipo(valor) {
      switch (valor) {
        case 'E':
          return 'Entrada';
        case 'S':
          return 'Saída';
        default:
          return '-';
      }
    },
    formataDinheiro(valor) {
      if (valor == null || valor == undefined) {
        return '-';
      }
      const formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      });
      return formatter.format(valor);
    },
    formataData(valor) {
      if (valor == null || valor == undefined) {
        return '-';
      }

      const partesData = valor.split('-');
      if (partesData.length === 3) {
        const dia = partesData[2];
        const mes = partesData[1];
        const ano = partesData[0];

        return `${dia}/${mes}/${ano}`;
      }
      return valor;
    },
    formataStatusPagamento(valor) {
      const status = {NP: 'Não Pago', PP: 'Parcialmente Pago', CP: 'Pago'};
      return status[valor];
    }
  },
};
