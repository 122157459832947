const listaMenus = [
  {
    showMenu: true,
    icone: 'mdi-office-building-cog-outline',
    dscmenu: 'Administração',
    menu: 'Administracao',
    submenu: [
      {
        showMenu: true,
        icone: 'mdi-menu-right',
        dscmenu: 'Perfis',
        menu: 'Perfis',
      },
      {
        showMenu: true,
        icone: 'mdi-menu-right',
        dscmenu: 'Usuários',
        menu: 'Usuarios',
      },
      {
        showMenu: true,
        icone: 'mdi-menu-right',
        dscmenu: 'Comunidade',
        menu: 'Comunidade',
      },
    ],
  },
  {
    showMenu: true,
    icone: 'mdi-card-account-details-outline',
    dscmenu: 'Cadastros',
    menu: 'Cadastros',
    submenu: [
      {
        showMenu: true,
        icone: 'mdi-menu-right',
        dscmenu: 'Faixas',
        menu: 'FaixasContrato',
      },
      {
        showMenu: true,
        icone: 'mdi-menu-right',
        dscmenu: 'Tabelas de preço',
        menu: 'TabelasPreco',
      },
      {
        showMenu: true,
        icone: 'mdi-menu-right',
        dscmenu: 'Contratos',
        menu: 'Contratos',
      },
      {
        showMenu: true,
        icone: 'mdi-menu-right',
        dscmenu: 'Contas',
        menu: 'Contas',
      },
      {
        showMenu: true,
        icone: 'mdi-menu-right',
        dscmenu: 'Caixas',
        menu: 'Caixas',
      },
      {
        showMenu: true,
        icone: 'mdi-menu-right',
        dscmenu: 'Formas de pagamento',
        menu: 'FormasPagamento',
      },
      {
        showMenu: true,
        icone: 'mdi-menu-right',
        dscmenu: 'Pessoas',
        menu: 'Pessoas',
      },
    ],
  },
  {
    showMenu: true,
    icone: 'mdi-bank-transfer',
    dscmenu: 'Transferêcia',
    menu: 'Transferencia',
  },
  {
    showMenu: true,
    icone: 'mdi-book-account-outline',
    dscmenu: 'Pagamentos',
    menu: 'Pagamentos',
  },
  {
    showMenu: true,
    icone: 'mdi-bank-circle-outline',
    dscmenu: 'Contas a receber/pagar',
    menu: 'ContasReceberPagar',
  },
  {
    showMenu: true,
    icone: 'mdi-file-chart-outline',
    dscmenu: 'Relatórios',
    menu: 'Relatorios',
    submenu: [
      {
        showMenu: true,
        icone: 'mdi-menu-right',
        dscmenu: 'Aniversariantes',
        menu: 'RelatorioAniversario',
      },
      {
        showMenu: true,
        icone: 'mdi-menu-right',
        dscmenu: 'Batismo',
        menu: 'RelatorioBatismo',
      },
      {
        showMenu: true,
        icone: 'mdi-menu-right',
        dscmenu: 'Confirmação',
        menu: 'RelatorioConfirmacao',
      },
      {
        showMenu: true,
        icone: 'mdi-menu-right',
        dscmenu: 'Faixa etária',
        menu: 'RelatorioFaixaEtaria',
      },
      {
        showMenu: true,
        icone: 'mdi-menu-right',
        dscmenu: 'Inadimplentes',
        menu: 'RelatorioInadimplentes',
      },
      {
        showMenu: true,
        icone: 'mdi-menu-right',
        dscmenu: 'Membros por faixa',
        menu: 'RelatorioMembrosPorFaixa',
      },
    ],
  },
];

export default listaMenus;
