<template>
  <div>
    <visualiza-dados :cabecalhos_colunas="cabecalhos_colunas" :id_registro="id_registro"
      :titulo_listagem="titulo_listagem" :apiConsultarRegistros="apiConsultarRegistros"
      :apiExcluirRegistro="apiExcluirRegistro" :coluna_ordenar="coluna_ordenar" @cadastrarItem="cadastrarItem"
      @editarItem="editarItem" @imprimirItem="imprimirItem" :mensagem_exclusao="mensagem_exclusao"
      :formataColuna="formatarColuna" :pode_imprimir="true" :filtros="filtros">

      <template v-slot:filtros>
        <v-row>
          <v-col cols="12" sm="4" md="4">
            <v-text-field v-model="filtros.numero" solo label="Número contrato" filled clearable />
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-autocomplete label="Faixa do contrato" v-model="filtros.faixas" :items="listaFaixas"
              item-value="idfaixacontrato" item-text="descricao" color="#94896d" filled autofocus multiple clearable />
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-checkbox v-model="filtros.somenteAtivos" solo label="Somente Ativos" color="blue" filled/>
          </v-col>
        </v-row>
      </template>
    </visualiza-dados>
  </div>
</template>

<script>
import VisualizaDados from '@/components/crud/VisualizaDados.vue';
import geradorPDF from '@/mixins/geradorPDF';
import { getApi, excluirApi } from '@/network/ApiService';
import { api } from '@/network/Api';
export default {
  components: {
    VisualizaDados,
  },
  mixins: [geradorPDF],
  data() {
    return {
      titulo_listagem: 'Contratos',
      cabecalhos_colunas: [
        {
          text: 'Número',
          value: 'contrato',
          sortable: true,
          filterable: true,
        },
        {
          text: 'Titular',
          value: 'titular',
          sortable: true,
          filterable: true,
        },
        {
          text: 'Faixa',
          value: 'faixa',
          sortable: true,
          filterable: true,
        },
        // {
        //   text: 'Cobrador',
        //   value: 'utiliza_cobrador',
        //   sortable: true,
        //   filterable: true
        // },
        {
          text: 'Assinatura',
          value: 'data_assinatura',
          sortable: true,
          filterable: true,
        },
        // {
        //   text: 'Desligamento',
        //   value: 'data_desligamento',
        //   sortable: true,
        //   filterable: true
        // },
        {
          text: 'Status',
          value: 'status',
          sortable: true,
          filterable: true,
        },
        {
          text: 'Ações',
          value: 'actions',
          align: 'end',
          sortable: false,
        },
      ],
      coluna_ordenar: 'numero_contrato',
      apiConsultarRegistros: api.contratoListarTodos,
      apiExcluirRegistro: api.contratoExcluir,
      apiImprimirRegistro: api.contratoImprimir,
      mensagem_exclusao: 'Tem certeza que deseja excluir este contrato?',
      id_registro: 'idcontrato',
      listaFaixas:[],
      filtros: {
        somenteAtivos: true,
        numero: '',
        faixas: [],
      },
    };
  },
  created() {
    getApi(api.faixaContratoListarTodas)
      .then((res) => {
        this.listaFaixas = res.data;
      })
      .catch((err) => {
        console.log(err);
      });

  },
  methods: {
    cadastrarItem() {
      this.$router.push({ name: 'ContratoInsert' });
    },
    editarItem(params) {
      this.$router.push({ name: 'ContratoEdit', params: { id: params.id } });
    },
    formataData(valor, coluna) {
      let dataOriginal = valor[coluna.value];
      if (dataOriginal == null || dataOriginal == undefined) {
        return '-';
      }

      const partesData = dataOriginal.split('-');
      if (partesData.length === 3) {
        const dia = partesData[2];
        const mes = partesData[1];
        const ano = partesData[0];

        return `${dia}/${mes}/${ano}`;
      }
      return dataOriginal;
    },
    formatarColuna(item, column) {
      if (column.value === 'data_assinatura') {
        return this.formataData(item, column);
      }

      // Se não for a coluna 'ativo', use o valor padrão
      return item[column.value];
    },

    async imprimirItem(params) {
      console.log('iniciando download');
      await this.gerarPDF(this.apiImprimirRegistro, params.id, 'contrato_' + params.dados.titular);
      console.log('fim download');
    },
  },
};
</script>

<style scoped></style>
