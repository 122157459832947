<template>
  <div>
    <div class="mx-12">
      <v-card v-show="!showProgresso" class="mx-auto my-12" style="background-color: #f8f9fa">

        <!-- Título de identificação da tela -->
        <v-card-title style="padding:12px 24px; margin-bottom: 45px">
          <v-row>
            <v-col cols="12">
              <h5 style="color: #94896d">{{ titulo_listagem }}</h5>
              <v-divider />
            </v-col>
          </v-row>
        </v-card-title>

        <v-row style="padding: 2rem 3rem;">
          <v-col cols="10">
            <v-row>
              <v-col cols="12" sm="4" md="4">
                <CampoData titulo="Data" v-model="filtros.data" />
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-select solo v-model="filtros.idconta" color="blue" hide-details :items="listaContas"
                  item-text="descricao" item-value="idconta" label="Conta" persistent-hint single-line clearable />
              </v-col>

              <v-col cols="12" sm="4" md="4">
                <v-autocomplete solo v-model="filtros.idpessoa" color="blue" hide-details :items="listaPessoas"
                  item-text="nome" item-value="idpessoa" label="Pessoa" persistent-hint single-line clearable />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="2">
            <v-row align-self="center" no-gutters style="height: 100%;">
              <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2" style="display: flex; align-items: end">
                <v-btn block elevation="4" x-large color="#990000" dark @click="buscarDados">
                  <span style="color: #ffffff">Consultar</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-card-title style="margin-bottom: 25px; padding: 12px 24px;">
          <v-row style="display: flex;">
            <v-col cols="12" xs="12" sm="12" md="10" lg="10" xl="10">
              <!-- Campo para pesquisar -->
              <v-text-field dense v-model="search" prepend-inner-icon="mdi-magnify" label="Pesquisa rápida" clearable
                solo-inverted hide-details color="#FFFFFF" style="background-color: #f5f4ec" />
            </v-col>
          </v-row>
        </v-card-title>

        <v-data-table :headers="cabecalhos_colunas" :items="registros" :search="search" :sort-by="coluna_ordenar"
          class="elevation-1" style="background-color: #f8f9fa" fixed-header height="40vh">
          <template v-slot:item="{ item }">
            <tr>
              <td v-for="column in cabecalhos_colunas" :key="column.value">
                <span v-if="column.type == 'date'">
                  {{ formataData(item[column.value]) }}
                </span>
                <span v-else-if="column.type == 'money'">
                  {{ formataDinheiro(item[column.value]) }}
                </span>
                <span v-else-if="column.type == 'actions'">
                  <v-icon @click="configuraData(item)">mdi-play</v-icon>
                </span>
                <span v-else>
                  {{ item[column.value] }}
                </span>
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-divider />

        <v-data-table :headers="cabecalhos_colunas_detalhados" :items="registros_detalhados" :search="search"
          :sort-by="coluna_ordenar" class="elevation-1" style="background-color: #f8f9fa" fixed-header height="40vh">
          <template v-slot:item="{ item }">
            <tr>
              <td v-for="column in cabecalhos_colunas_detalhados" :key="column.value">

                <span v-if="column.type == 'date'">
                  {{ formataData(item[column.value]) }}
                </span>
                <span v-else-if="column.type == 'money'">
                  {{ formataDinheiro(item[column.value]) }}
                </span>
                <span v-else-if="column.type == 'status_pagamento'">
                  {{ formataStatusPagamento(item[column.value]) }}
                </span>
                <span v-else>
                  {{ item[column.value] }}
                </span>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>

      <tela-carregando :showProgresso="showProgresso" tituloTemporizador="Aguarde...:" />
    </div>
  </div>
</template>

<script>

import formatacaoMixin from '@/mixins/formatacao';
import RelatorioView from '@/components/relatorios/RelatorioView.vue';
import visualizaDadosListagem from '@/components/crud/VisualizaDadosListagem.vue';
import { api } from '@/network/Api';
import { getApi } from '@/network/ApiService';
import { format, parseISO } from 'date-fns'

export default {
  components: {
    RelatorioView,
    visualizaDadosListagem,
  },
  mixins: [formatacaoMixin],
  data() {
    return {
      titulo_listagem: 'Contas a receber/pagar',
      search: '',
      coluna_ordenar: 'data_movimentacao',
      id_registro: 'idpagamento',
      apiContas: api.contaListarTodas,
      apiConsultarRegistros: api.contasReceberPagar,
      apiConsultarRegistrosDetalhados: api.contasReceberPagarDia,

      filtros: {
        data: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
        idpessoa: '',
        idconta: '',
      },
      registros: [],
      registros_detalhados: [],
      listaPessoas: [],
      listaContas: [],
      cabecalhos_colunas: [],
      cabecalhos_colunas_detalhados: [],
      showProgresso: true,
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.buscarContas();
      this.buscarPessoas();
      this.buscarDados();
    },
    buscarPessoas() {
      getApi(api.pessoaListarTodas)
        .then((res) => {
          this.listaPessoas = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    buscarContas() {
      getApi(api.contaListarTodas)
        .then((res) => {
          this.listaContas = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    buscarDados() {
      getApi(this.apiConsultarRegistros, null, this.filtros)
        .then((res) => {
          this.registros = res.data.dados;
          this.cabecalhos_colunas = [...res.data.header, { text: 'Ações', value: 'ação', 'type': 'actions' }];

        })
        .catch((err) => { })
        .finally(() => {
          setTimeout(() => {
            this.showProgresso = false;
          }, 100);
        });
      getApi(this.apiConsultarRegistrosDetalhados, null, this.filtros)
        .then((res) => {
          this.registros_detalhados = res.data.dados;
          this.cabecalhos_colunas_detalhados = res.data.header;
        })
        .catch((err) => { })
        .finally(() => {
          setTimeout(() => {
            this.showProgresso = false;
          }, 100);
        });
    },
    async configuraData(item) {
      this.filtros.data = item.data;
      this.buscarDados();
    }
  },

};
</script>

<style scoped></style>
