<template>
  <!-- Barra de navegação -->
  <nav class="barra-navegacao">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="logo-menu">
          <img :src=img_logo />
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>
    
    <v-list class="barra-navegacao-lista" dense shaped>
      <div v-for="(item, i) in items" :key="i">
        <v-list-group no-action v-if="item.submenu && item.showMenu">
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon color="#990000">{{ item.icone }}</v-icon>
            </v-list-item-icon>
            
            <v-list-item-content>
              <v-list-item-title style="color: #94896d">{{ item.dscmenu }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="(itemSubmenu, index) in item.submenu" 
            :key="index" 
            v-show="itemSubmenu.showMenu"
            style="padding-left: 20px;"
          >
            <v-list-item-icon style="margin-right: 2px;">
              <v-icon color="#990000">{{ itemSubmenu.icone }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content @click="acaoMenu(itemSubmenu.menu)">
              <v-list-item-title>
                <span style="color: #94896d">{{ itemSubmenu.dscmenu }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <!-- Menu que não possui agrupamento -->
        <template v-else>
          <v-list-item v-show="item.showMenu">
            <v-list-item-icon>
              <v-icon color="#990000">{{ item.icone }}</v-icon>
            </v-list-item-icon>
            
            <v-list-item-content @click="acaoMenu(item.menu)">
              <v-list-item-title style="color: #94896d">{{ item.dscmenu }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </div>
      
      <!-- Menu sair -->
      <v-list-item link >
        <v-list-item-icon>
          <v-icon color="#990000"> mdi-exit-to-app </v-icon>
        </v-list-item-icon>

        <v-list-item-content @click="logoff()">
          <v-list-item-title>
            <span style="color: #94896d">Sair</span>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  
  </nav>
</template>

<script>
import mixin from "@/store/mixin.js";

export default {
  mixins: [mixin],
  created () {
      this.initialize ();    
  },
  props: {
    img_logo: {
      type: String,
    },
    items: {
      type: Array,
    },
  },
  data () {
    return {
      // showUsuarios: false
    }
  },
  methods: {
    initialize () {
    },
    acaoMenu (pMenu) {
      this.executaMenu(pMenu);
    },
    logoff () {
      this.logout();
    }
  }  
}
</script>

<style scoped>
  .barra-navegacao {
    background-color: #f5f4ec;
    border-top: 1px solid #94896d;
    border-right: 1px solid #94896d;
  }

  .barra-navegacao-lista {
    background-color: #f5f4ec;
    border-bottom: 1px solid #94896d;
    height: 100vh;
  }

  .logo-menu {
    display: flex; 
    justify-content: center;
  }
</style>