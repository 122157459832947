<template>
  <div>
    <relatorio-view :showProgresso="showProgresso" tituloRelatorio="Relatório de inadimplentes">
      <template v-slot:filtros>
        <v-row>
          <v-col cols="12" sm="4" md="4">
            <v-autocomplete v-model="filtros.idpessoa" :items="listaPessoas" filled clearable
              color="blue-grey lighten-2" label="Membro" item-text="nome" item-value="idpessoa" />
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-select v-model="filtros.tipoPagamento" :items="listaTipoPagamento" item-text="nome" item-value="valor"
              label="Tipo Pagamento" clearable filled />
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-checkbox v-model="filtros.somenteAtivos" label="Somente Ativos" color="blue" />
          </v-col>
        </v-row>
      </template>

      <template v-slot:filtros-buscar>
        <v-row justify="center" align-self="center" no-gutters style="height: 100%;">
          <v-col cols="12" sm="auto" md="auto" align-self="center">
            <v-btn block elevation="4" x-large color="#990000" dark @click="buscarDados">
              <span style="color: #ffffff">
                <v-icon>mdi-magnify</v-icon>
                Buscar
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </template>

      <template v-slot:dados>
        <v-data-table :headers="cabecalhos_colunas" :items="registros" :search="search" :sort-by="coluna_ordenar"
          class="elevation-1" style="background-color: #f8f9fa" height="65vh" fixed-header>
          <template v-slot:top>
            <v-toolbar flat>
              <v-spacer></v-spacer>
              <v-btn @click="imprimir()">
                <v-icon>mdi-printer-outline</v-icon>
                Imprimir
              </v-btn>
            </v-toolbar>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td v-for="column in cabecalhos_colunas" :key="column.value">

                <span v-if="column.value == 'vencimentos'">
                  <v-icon @click="abrirDetalhes(item)" color="grey lighten-1">mdi-account-details-outline</v-icon>
                </span>
                <span v-else-if="column.value == 'status'">
                  <v-chip :color="getColor(item.status)" dark>
                    {{ item.status }}
                  </v-chip>
                </span>
                <span v-else-if="column.value == 'valor_vencimento'">
                  {{ formatarParaReais(item.valor_vencimento) }}
                </span>
                <span v-else>
                  {{ formataColuna(item, column) }}
                </span>
              </td>
            </tr>
          </template>
          <template v-slot:footer>
            <v-row style="background:#ffffff4d;border: 1px solid #b1b1b1;margin: 8px;">
              <v-col cols="4" style="text-align: left; padding: 1rem 2rem;">
                Quantidade: {{ qtdTotal }} registros
              </v-col>
              <v-col cols="4" style="text-align: right; padding: 1rem 2rem;">
              </v-col>
              <v-col cols="4" style="text-align: right; padding: 1rem 2rem;">
                Valor total: {{ valorTotal }}
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </template>
    </relatorio-view>
    <v-dialog v-model="detalhesInadiplenteVisivel" width="1024px">
      <v-card>
        <v-card-title class="d-flex align-center">
          <div>
            <span>
              Nome:
            </span>
            <span class="mb-0">
              {{ detalhesInadiplenteInformacao.pessoa }}
            </span>

            <small class="text--secondary">
              ({{ detalhesInadiplenteInformacao.status }})
            </small>
          </div>
        </v-card-title>

        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Pagamento</th>
                <th class="text-left">Data</th>
                <th class="text-left">Documento</th>
                <th class="text-left">Parcela</th>
                <th class="text-left">Valor</th>
                <th class="text-left">Ação</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, k) in detalhesInadiplenteInformacao.vencimentos" :key="k">
                <td>{{ item.idpagamento }}</td>
                <td>{{ formataData(item.data_vencimento) }}</td>
                <td>{{ item.documento }}</td>
                <td>{{ item.numero_parcelas }} / {{ item.parcela_final }}</td>
                <td>{{ formatarParaReais(item.valor) }}</td>
                <td @click="abrirPagamento(item)">
                  <v-icon>mdi-arrow-top-right-bold-box-outline</v-icon>
                </td>
              </tr>
              <tr>
                <td>TOTAL</td>
                <td colspan="4"></td>
                <td>R$ {{ detalhesInadiplenteInformacao.valor_vencimento }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="detalhesInadiplenteVisivel = false">
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DialogMsgAlerta from '@/components/dialogs/DialogMsgAlerta.vue';
import RelatorioView from '@/components/relatorios/RelatorioView.vue';
import { api } from '@/network/Api';
import { getApi } from '@/network/ApiService';
import geradorPDF from '@/mixins/geradorPDF';

export default {
  components: {
    DialogMsgAlerta,
    RelatorioView,
  },
  mixins: [geradorPDF],
  created() {
    this.initialize();
  },

  data() {
    return {
      showProgresso: true,
      registros: [],
      search: '',
      coluna_ordenar: '',
      cabecalhos_colunas: [],
      listaPessoas: [],
      apiConsultarRegistros: api.relatorioInadimplentes,
      apiImprimirRegistros: api.relatorioInadimplentesImprimir,
      filtros: {
        somenteAtivos: true,
        idpessoa: '',
        tipoPagamento: ''
      },
      listaTipoPagamento: [
        { nome: "Todos", valor: "" },
        { nome: "Entradas", valor: "E" },
        { nome: "Saídas", valor: "S" }
      ],
      detalhesInadiplenteVisivel: false,
      detalhesInadiplenteInformacao: [],
    };
  },
  computed: {
    valorTotal() {
      let valor = this.registros.reduce((total, registro) => total + parseFloat(registro.valor_vencimento), 0);
      return this.formatarParaReais(valor);
    },
    qtdTotal() {
      let valor = this.registros.length;
      return valor;
    }
  },
  methods: {
    initialize() {
      this.buscarPessoas();
      this.buscarDados();
    },

    buscarPessoas() {
      getApi(api.pessoaListarTodas)
        .then((res) => {
          this.listaPessoas = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    buscarDados() {
      this.showProgresso = true;
      getApi(this.apiConsultarRegistros, null, this.filtros)
        .then((res) => {
          this.registros = res.data.dados;
          this.cabecalhos_colunas = res.data.header;
        })
        .catch((err) => { })
        .finally(() => {
          setTimeout(() => {
            this.showProgresso = false;
          }, 100);
        });
    },
    async imprimir() {
      console.log("iniciando download");
      let nomeArquivo = "relatorio";
      try {
        const response =  await getApi(this.apiImprimirRegistros, null, this.filtros, {
          responseType: 'blob',
        });
        if (response.status === 200) {
          const base64 = response.data;
          const filename = `${nomeArquivo}.pdf`;
          // Converte a string Base64 para um array de bytes
          const byteCharacters = window.atob(base64);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);

          // Cria um objeto Blob a partir do array de bytes
          const blob = new Blob([byteArray], { type: 'application/pdf' });

          // Cria uma URL temporária para o Blob e inicia o download
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        } else {
          console.error(`Erro ao baixar o PDF. Status: ${response.status}`);
        }
      } catch (error) {
        console.error(`Falha ao baixar o PDF: ${error.message}`);
      }
    },

    formataColuna(item, column) {
      return item[column.value];
    },
    getColor(status) {
      if (status.toLowerCase() == 'ativo') return 'green'
      else return 'orange'
    },
    formataData(dataOriginal) {
      const partesData = dataOriginal.split('-');
      if (partesData.length === 3) {
        const dia = partesData[2];
        const mes = partesData[1];
        const ano = partesData[0];

        return `${dia}/${mes}/${ano}`;
      }
      return dataOriginal;
    },
    formatarParaReais(valor) {
      const formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      });
      return formatter.format(valor);
    },
    abrirDetalhes(item) {
      this.detalhesInadiplenteInformacao = item;
      this.detalhesInadiplenteVisivel = true;
    },
    abrirPagamento(params) {
      const url = this.$router.resolve({
        name: 'PagamentoEdit',
        params: { id: params.idpagamento }
      }).href;

      window.open(url, '_blank');
    }
  },
};
</script>
