import { render, staticRenderFns } from "./ContratoHistoricoView.vue?vue&type=template&id=02bcb0b6&scoped=true"
import script from "./ContratoHistoricoView.vue?vue&type=script&lang=js"
export * from "./ContratoHistoricoView.vue?vue&type=script&lang=js"
import style0 from "./ContratoHistoricoView.vue?vue&type=style&index=0&id=02bcb0b6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02bcb0b6",
  null
  
)

export default component.exports