<template>
  <div>

    <insere-dados-v2 :titulo_formulario="titulo_formulario" :apiInserirRegistro="apiInserirRegistro"
      :campos_formulario="dados" @saveCompleted="saveCompleted" :showProgresso="showProgresso" @close="close">
      <!-- Campos da tabela de preço -->
      <v-row>
        <v-col cols="8">
          <v-text-field type="text" label="Descrição:" v-model="dados.descricao" autofocus color="#94896d" dense />
        </v-col>
        <v-col cols="4">
          <CampoData titulo="Data Transferência" v-model="dados.dt_criacao" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-select label="Conta Origem:" :items="listaContas" item-value="idconta" item-text="descricao"
            v-model="dados.idconta_origem" color="#94896d" dense />
        </v-col>
        <v-col cols="4">
          <v-select label="Conta Destino:" :items="listaContas" item-value="idconta" item-text="descricao"
            v-model="dados.idconta_destino" color="#94896d" dense />
        </v-col>
        <!-- <v-col cols="3">
          <v-select label="Forma Pagamento:" :items="listaFormasPagamento" item-value="idformapagamento"
            item-text="descricao" v-model="dados.idformapagamento" color="#94896d" dense />
        </v-col> -->
        <v-col cols="4">
          <v-text-field type="text" ref="valor" label="Valor:" v-model="dados.valor"
            @input="dados.valor = formatarMoeda(dados.valor)" color="#94896d" dense>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>

        <!-- <v-col cols="4">
          <v-select label="Caixa:" :items="listaCaixas" item-value="idcaixa" item-text="descricao"
            v-model="dados.idcaixa" color="#94896d" dense />
        </v-col>
        <v-col cols="4">
          <v-select label="Contrato:" :items="listaContratos" item-value="idcontrato" item-text="titular"
            v-model="dados.idcontrato" color="#94896d" dense />
        </v-col> -->
      </v-row>
    </insere-dados-v2>
  </div>
</template>
<script>
import axiosInstance from '@/network';
import mixin from '@/store/mixin.js';
import InsereDadosV2 from '@/components/crud/InsereDadosV2.vue';
import { format, parseISO } from 'date-fns';

import { api } from '@/network/Api';
import { getApi } from '@/network/ApiService';

export default {
  components: {
    InsereDadosV2
  },
  created() {
    this.initialize();
  },
  mixins: [mixin],
  data() {
    return {
      titulo_formulario: 'Criação de transferência',
      apiInserirRegistro: api.transferenciaCriar,
      nome_rota_listagem_dados: 'Transferencia',
      nome_rota_edicao_dados: 'TransferenciaEdit',
      listaContas: [],
      listaFormasPagamento: [],
      listaContratos: [],
      listaCaixas: [],
      dados: {
        descricao: '',
        valor: 0,
        idconta_origem: '',
        idconta_destino: '',
        idformapagamento: '',
        dt_criacao: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
        idcaixa: '',
        idcontrato: '',
      },
      error: undefined,
    };
  },
  methods: {
    initialize() {
      this.showProgresso = true;

      getApi(api.contaListarTodas)
        .then((res) => {
          this.listaContas = res.data;
        })
        .catch((err) => {
          console.log(err);
        });

      getApi(api.formaPagamentoListarTodas)
        .then((res) => {
          this.listaFormasPagamento = res.data;
        })
        .catch((err) => {
          console.log(err);
        });

      getApi(api.contratoListarTodos)
        .then((res) => {
          this.listaContratos = res.data;
        })
        .catch((err) => {
          console.log(err);
        });

      getApi(api.caixaListarTodas)
        .then((res) => {
          this.listaCaixas = res.data;
        })
        .catch((err) => {
          console.log(err);
        });


      setTimeout(() => {
        this.showProgresso = false;
      }, 100);

    },

    close() {
      this.dialog = false;
      this.clearForm();
      this.initialize();
    },
    salvar() {
      this.error = null;

      if (this.dados.descricao == '') {
        this.error = 'O campo descrição é obrigatório';
      }
      if (this.dados.idconta_origem == '') {
        this.error = 'A conta de origem é obrigatória';
      }
      if (this.dados.idconta_destino == '') {
        this.error = 'A conta de destino é obrigatória';
      }

      if (this.error) {
        setTimeout(() => { this.error = undefined; }, 2000);
        return;
      }



      axiosInstance
        .post(this.apiInserirRegistro, this.dados)
        .then((res) => {
          if (res.data) {
            this.error = undefined;
            this.close();
          }
        })
        .catch((err) => {
          var msgErro = err.response.data.err;
          this.error = msgErro;
        });

    },

    saveCompleted(dados) {
      this.$router.push({ name: this.nome_rota_listagem_dados });
      // this.$router.push({ name: this.nome_rota_edicao_dados, params: { id: dados.idtransferencia } });
    },
    close() {
      this.$router.push({ name: this.nome_rota_listagem_dados });
    },
  },
};
</script>

<style scoped>
.progresso-circular {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: -200px;
  padding: 0px;
}
</style>
@/network