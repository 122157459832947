import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import store from '@/store';
import VueMask from 'v-mask';
import money from 'v-money';
import VueProgressBar from 'vue-progressbar';
import Vuelidate from 'vuelidate';
import Vue2Filters from 'vue2-filters';

import CampoData from '@/components/inputs/CampoData.vue';
import TelaCarregando from '@/components/TelaCarregando.vue';

Vue.use(VueProgressBar, {
  color: 'rgb(143, 255, 199)',
  failedColor: 'red',
  height: '2px',
});


Vue.use(Vue2Filters);

Vue.config.productionTip = false;
Vue.use(VueMask);
Vue.use(money, {precision: 4});
Vue.use(Vuelidate);
Vue.component('CampoData', CampoData);
Vue.component('TelaCarregando', TelaCarregando);

  new Vue({
    store,
    router,
    vuetify,
    render: (h) => h(App),
  }).$mount('#app');
