import { render, staticRenderFns } from "./ContratoView.vue?vue&type=template&id=446c1406&scoped=true"
import script from "./ContratoView.vue?vue&type=script&lang=js"
export * from "./ContratoView.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "446c1406",
  null
  
)

export default component.exports