<template>
  <div>
    <visualiza-dados 
      :cabecalhos_colunas="cabecalhos_colunas"
      :id_registro="id_registro"
      :titulo_listagem="titulo_listagem" 
      :apiConsultarRegistros="apiConsultarRegistros"
      :apiExcluirRegistro="apiExcluirRegistro" 
      :coluna_ordenar="coluna_ordenar" 
      @cadastrarItem="cadastrarItem"
      @editarItem="editarItem" 
      :mensagem_exclusao="mensagem_exclusao"
    />
  </div>
</template>

<script>
import VisualizaDados from '@/components/crud/VisualizaDados.vue';
import { api } from '@/network/Api';

export default {
  components: {
    VisualizaDados
  },
  data() {
    return {
      titulo_listagem: 'Contas',
      cabecalhos_colunas: [
        {
          text: 'Descrição',
          value: 'descricao',
          align: 'start',
          sortable: true,
          filterable: true
        },
        {
          text: 'Ações',
          value: 'actions',
          align: 'end',
          sortable: false
        }
      ],
      coluna_ordenar: 'descricao',
      apiConsultarRegistros: api.contaListarTodas,
      apiExcluirRegistro: api.contaExcluir,
      mensagem_exclusao: 'Tem certeza que deseja excluir esta conta?',
      id_registro: 'idconta',
    }
  },
  methods: {

    cadastrarItem() {
      this.$router.push({ name: 'ContaInsert' });
    },
    editarItem(params) {
      this.$router.push({ name: 'ContaEdit', params: { id: params.id } });
    },
  },
};
</script>

<style scoped></style>